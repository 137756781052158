import React, { Fragment } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Image from 'next/image'
import { useRouter } from 'next/router'
import styles from '../LeverageProfileBuilder/leverage.module.scss'
import { usePageAttributes } from '../../Hooks/usePageAttributes'
import { trackEventWithAuthStatus } from '../../utils/mixpanel'
import { useSelector } from 'react-redux'

const Leverage = ({ choice }) => {
  const router = useRouter()
  const auth = useSelector((state) => state.auth)

  const { getPageId } = usePageAttributes()
  let buttonId = getPageId() + '-profie-builder'

  const redirect = () => {
    let page
    switch (router.asPath) {
      case '/ms-admissions':
        page = 'ms_advising_web'
        break
      case '/sat-tutoring':
        page = 'sat_tutoring'
        break
      case '/ib-tutoring':
        page = 'ib_tutoring_web'
        break
      case '/ivy-league-undergraduate-admissions':
        page = 'ug_advising_web'
        break
      default:
        page = 'home_web'
    }
    trackEventWithAuthStatus(auth, `click_book_consultation_profile_builder_${page}`, { buttonId })

    choice ? router.push('/contact/' + choice.toString()) : router.push('/contact')
  }

  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: 'rgb(90, 30, 203)',
          color: 'rgb(248, 248, 248)',
          backgroundImage: 'url("box-bg/profile_builder_bg.jpg")',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row>
            <Col className={styles.AidenSection}>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Row>
                    <Col className={styles.profileBuilderImg}>
                      <Image
                        src="/profile_builder-image.png"
                        width="700"
                        height="500"
                        //className={}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className={styles.levTextSection}>
                  <Row>
                    <Col>
                      <h2>BUILD YOUR PROFILE WITH COLLEGEPASS PROFILE BUILDER</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3>
                        CollegePass Profile Builder is an Advanced College Admissions Platform developed based on a
                        study of 100+ Ivy League/UK/Canada Case Studies!
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {router.pathname.startsWith('/google-form') ||
                      router.pathname.startsWith('/google-ivy-league') ? (
                        <Button id={buttonId} href="#google-contact">
                          Book Your Free Consultation
                        </Button>
                      ) : (
                        <Button id={buttonId} onClick={redirect}>
                          Book Your Free Consultation
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  )
}

export default Leverage
