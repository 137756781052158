import React, { Fragment } from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import styles from './faq.module.scss'

const Faq = () => {
  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: '#000000',
          paddingBottom: '18px',
        }}
      >
        <Container className="p-0">
          <Row>
            <Col className={styles.faqV3Heading}>
              <h3>Frequently asked questions</h3>
            </Col>
          </Row>
          <Row className={styles.accoVer3}>
            <Col>
              <p>General</p>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>What is CollegePass?</Accordion.Header>
                  <Accordion.Body>
                  CollegePass is an international college admissions company serving students from diverse locations, including the US, India, UK, Singapore, and over 10 other countries. CollegePass has teamed up with students from more than 1200 schools globally, and they are attending The Ivy League, University of Cambridge, Johns Hopkins, Georgia Tech, Carnegie Mellon, NUS, Imperial College London, and more.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>How does CollegePass help students and parents?</Accordion.Header>
                  <Accordion.Body>
                  CollegePass offers personalized services, including one-on-one college admissions advising, profile building, essay and Statement of Purpose (SOP) editing, summer planning, extracurricular strategy development, research mentoring, scholarship/financial aid strategy development, and IB/IGCSE/SAT/AP tutoring.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Does CollegePass provide guaranteed admissions to colleges?</Accordion.Header>
                  <Accordion.Body>
                  100% of CollegePass students received offers! Notably, each student secured at least 3 admission offers, underscoring the effectiveness of our tailored approach in navigating the complex admissions landscape.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>

          {/* <Row className={styles.accoVer3}>
            <Col>
              <p>Pricing</p>
              <Accordion defaultActiveKey="4">
                <Accordion.Item eventKey="3">
                  <Accordion.Header>What are the fees for CollegePass's college counseling and tutoring services?</Accordion.Header>
                  <Accordion.Body>
                  CollegePass counseling services start from INR 250k+ taxes for the comprehensive package, and tutoring services from INR 2k+ taxes per hour.<br/><br/>
                  CollegePass+ subscription fee is INR 999 for monthly access and INR 9999 for annual access.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row> */}
        </Container>
      </Container>
    </Fragment>
  )
}

export default Faq
